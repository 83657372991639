/**
 * Templating
 */



/**
 * Parse a HTML template
 *
 * @param {string} tmpl
 * @param {object} data
 *
 * @return {string}
 */
function biro_html_template_parse( tmpl, data ) {

	var val;

	for ( var prop in data ) {

		if ( data.hasOwnProperty( prop ) ) {

			val  = ( data[ prop ] === 'null' || ! data[ prop ] ) ? '' : data[ prop ];
			tmpl = tmpl.replace( '{{' + prop + '}}', val );

		}

	}

	return tmpl;

}
