/*
 * Mobile menu toggle
 */

// On page load
jQuery(function($) {

	// Show and hide
	$('#mobile-menu-toggle').on('click', function(){

		if ( ! $('#mobile-menu-toggle').hasClass('is-active') ) {

			$('#mobile-menu-wrapper').slideDown(300,
				function () {
					$('#mobile-menu-toggle').addClass('is-active').blur();
				}
			);

		} else {

			$('#mobile-menu-wrapper').slideUp(300,
				function() {
					$('#mobile-menu-toggle').removeClass('is-active').blur();
				}
			);

		}

	});

	// Bottom border
	$( window ).on( 'scroll', function() {

		if ( $( window ).scrollTop() > 10 ) {

			$('#header').addClass('scrolled');

		} else {

			$('#header').removeClass('scrolled');

		}

	});

});
