/*
 * Search toggles
 */


// On page load
jQuery(function($) {

	// Desktop search toggle
	$('#menu-search-toggle').on('click', function(){

		if ( ! $('body').hasClass('search') ) {

			if ( ! $('#menu-search-form').hasClass('is-active') ) {

				$('#menu-search-toggle .search, #menu-search-toggle .search-close').fadeToggle( 0 );

				$('#menu-search-wrapper').slideDown(300,
					function () {
						$('#menu-search-text').focus();
						$('#menu-search-form').addClass('is-active');
					}
				);

			} else {

				$('#menu-search-toggle .search, #menu-search-toggle .search-close').fadeToggle(0);
				$('#menu-search-text').blur();

				$('#menu-search-wrapper').slideUp(300,
					function() {
						$('#menu-search-form').removeClass('is-active');
					}
				);

			}

		}

	});

});
