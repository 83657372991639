/*
 * Bootstrap-specific script
 */

/* global biro_body  */


// On page load
jQuery(function($) {

	// Bootstrap - focus first input when collapsed element opens
	biro_body.on('shown.bs.collapse', '.biro-collapse-focus', function() {
		$(this)
			.find('input')
			.first()
			.focus();
	});

});
