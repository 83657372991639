
// On page load
jQuery(function($) {

	$( '.comment-reply-link' ).on( 'click', function( e ) {

		e.preventDefault();

		$( '.comment-reply-link' ).removeClass( 'is-active' );

		$( this ).addClass( 'is-active' );

		$( '#reply-title' ).html( 'Add a reply' );

		var comment_reply_id = $( this ).attr( 'data-commentid' );

		$( '#comment_parent' ).val( comment_reply_id );

		$('#comment').focus();

	} );

});
