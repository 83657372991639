/*
 * Privacy notice script
 */

/* global Cookies  */


// On page load
jQuery(function($) {

	// Privacy notice
	function privacy_notice_close( cookies_decision ) {

    Cookies.set('privacy-cookies', cookies_decision, { expires: 365 });

    // slideUp() doesn't deal well with min-height
    $('#privacy-notice').css( {
    	'height': $('#privacy-notice').outerHeight(),
    	'min-height': 'auto'
    } ).slideUp(200);

	}

  if ( Cookies.get('privacy-cookies') === undefined && navigator.doNotTrack !== '1' && window.doNotTrack !== '1' ) {
    document.getElementById('privacy-notice').style.display = 'flex';
  }

  if ( navigator.doNotTrack === '1' || window.doNotTrack === '1' ) {
    Cookies.set('privacy-cookies', 'reject', { expires: 1 });
  }

  $('#privacy-cookies-accept').on( 'click', function() {
    privacy_notice_close( 'accept' );
  });

  $('#privacy-cookies-reject').on( 'click', function() {
    privacy_notice_close( 'reject' );
  });

});
