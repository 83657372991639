
/**
 * Promo popup script
 */

jQuery( function( $ ) {

	// Init
	var pp_cookie, pp_slug, pp_slug_regex, pp_timer;
	var pp = $( '.promo-popup' );

	// Active?
	if (
		biro_body.hasClass( 'promo-popup-active' ) &&
		pp.length
	) {

		// Init
		pp_cookie = Cookies.get( 'biro-promo-popup' );
		if ( typeof pp_cookie !== 'undefined' ) {
			pp_cookie = JSON.parse( Cookies.get( 'biro-promo-popup' ) );
		} else {
			pp_cookie = [];
		}

		// Has the user dismissed this one?
		pp_slug_regex = /[^a-z0-9]/g;
		pp_slug       = pp.find( '.heading' ).text().toLowerCase().replace( pp_slug_regex, '' );
		if ( pp_cookie.indexOf( pp_slug ) === -1 ) {

			// Set timer going - 20 seconds
			pp_timer = setTimeout(
				function() {

					// Show popup
					pp.fadeIn( 200 );

				},
				1000 * 20
			);

		}

		// Dismiss button
		biro_body.on( 'click', '.promo-popup-dismiss', function() {

			// Purge after 10 dismisses
			if ( pp_cookie.length > 10 ) {
				pp_cookie = [];
			}

			// Add this popup
			pp_cookie.push( pp_slug );

			// Set cookie
			Cookies.set( 'biro-promo-popup', JSON.stringify( pp_cookie ), { expires: 365 } );

			// Hide and remove popup
			pp.fadeOut( 200, function() {
				pp.remove();
			});

		});

	}

} );
