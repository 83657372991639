// Global elements
var biro_body;

jQuery(function($) {

	// Set biro_body variable
	biro_body = $('body');

	// Preload images
	var preload;
	preload = new Image();
	preload.src = biro_main.ajax_spinner_url;

	// Hide stuff that needs to begin hidden with JS
	// (but doesn't use hidden-js class cos it can't be overridden with jQuery.show() etc.)
	$('.hidden-by-js').hide();

	// Fitvids
	$('.fitvids,.content-styles').fitVids();

});
