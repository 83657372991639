jQuery( function( $ ) {

  $( '.filter-select' ).on( 'change', function() {

    var changed_filter = $(this);

    $( '.filter-select' ).not( changed_filter ).val( '0' );

    changed_filter.closest( 'form' ).submit();

  } );

} );
